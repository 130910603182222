import React from "react";
import Header from "../components/Header";


function Main() {
  return (
    <div className="container">
      <Header/>
      <div>
        <div className="v46_57">
          <div className="v46_58">
          </div>
          <div className="v46_97"/>
          <div className="v46_63">
            <div className="v46_64"></div>
            <span className="v46_65">
              고객 / 사용자 관리는
              <p className="v46_66">calenda.one</p>
            </span>
            <div className="v46_67"></div>
            <div className="v46_68"></div>
            <div className="v46_69"></div>
            <div className="v46_70"></div>
            <div className="v46_71"></div>
            <div className="v46_72"><span className="v46_73">회원가입</span><span className="v46_74">로그인</span>
              <div className="v46_75"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Main;
